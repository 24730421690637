import { defineStore } from 'pinia';
import { storeDbActions } from '@/helpers';

export const useDeviceTypeStore = defineStore({
    id: 'devicetype',
    state: () => ({
        baseUrl : `${import.meta.env.VITE_API_URL}/devicetype`
    }),
    actions: {
        ...storeDbActions(),
    }
});
