import { defineStore } from 'pinia';
import { storeDbActions } from '@/helpers';

export const useSystemStore = defineStore({
    id: 'SystemStore',
    state: () => ({    
        baseUrl : `${import.meta.env.VITE_API_URL}/cms/system`,
    }),

    actions: {
        ...storeDbActions(),
    }
});
