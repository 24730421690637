export function checkMacAddress(o) {
    //var o = $('#'+fieldname);
    var E = o[0].selectionEnd;
    var S = o[0].selectionStart;

    var s=o.val().trim();
    s = s.toUpperCase().replaceAll(":","-");

    o.val(s);

    o[0].selectionEnd = E;
    o[0].selectionStart = S;

    var regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;

    return regex.test(s);
}


export function isMobile() {
    return true; // DEBUG
   return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))?true:false;
 }

 export function formatTime(t) {    
    t = String(t).replace(":","").padStart(4,'0');
    t = t.slice(0,2)+':'+t.slice(2,4);
    return t=="00:00"?null:t;
}


export function toLocaleDateTime(value) {    
    return new Date(value+(value.indexOf('.')<0?'.0000Z':'Z'));
} 

export function toLocaleDateTimeString(value) {
    var date = new Date(value+(value.indexOf('.')<0?'.0000Z':'Z'));
            
    return date.getFullYear()+'-'+
        String(date.getMonth()+1).padStart(2,'0')+'-'+
        String(date.getDate()).padStart(2,'0')+"T"+
        String(date.getHours()).padStart(2,'0')+':'+
        String(date.getMinutes()).padStart(2,'0')+':'+
        String(date.getSeconds()).padStart(2,'0');
}    

export function parseBool(b) {
    if(typeof b == "boolean") return b;
    if(typeof b == "string") return b.toLowerCase()=="true";
    return b;
}

export function countIcon(icon, count, title, className) {
    return `<span class="position-relative">`+
    `<a class="bi `+icon+`" style="padding:0;padding-right : 15px;" data-toggle="tooltip" data-placement="top" title="` + title + `"/>` +
    `<span class="`+className+` position-absolute top-0 start-60 translate-middle badge rounded-pill bg-` + (count ? 'success' : 'danger') + `" style="margin-left:0px;font-size: 0.45em;">` +
    count +`</span></span>`;    
}