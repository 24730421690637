import { defineStore } from 'pinia';

export const useStatusStore = defineStore({
    id: 'status',
    state: () => ({
        isSubmitting : false,

        error : "",
        status : "",
        loading : "",
    }),
    actions: {
        ss(errorMsg, statusMsg="", loadingMsg="", submitting =false){
            this.$patch({
                error : errorMsg,
                status : statusMsg,
                loading : loadingMsg,
                isSubmitting : submitting,
            });
        },
        startLoading(loadingMsg, submitting =true){
            this.ss("","", loadingMsg?loadingMsg:"general.loading", submitting);
        },        
        stopLoading(submitting =false){
            this.ss("","", "", submitting);
        },
    }
});




