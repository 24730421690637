<script>
import { defineComponent} from 'vue';
import { useRoute } from 'vue-router';
import { activateHints } from '@/helpers';

export default defineComponent( {
    name : "Grid",
    props: {
        caption : { required: false, type: String, default : "" },
        // Filters op locale data. Na ontvangst
        localfilters : { required: false, type: Object, default : {} },
        // Filters voor API call (Ook paginatie)
        remotefilters : { required: false, type: Object, default : {} },

        filterenabled : { required: false, type: Boolean, default : true },
        sortenabled : { required: false, type: Boolean, default : true },
        paginationenabled : { required: false, type: Boolean, default : true },
        updateurlenabled : { required: false, type: Boolean, default : true }, // If true , de browsers URL wordt geupdeet

        onLoad: { required: true, type: Function },
        onBeforeLoad : { required: false, type: Function },
        onAfterLoad: { required: false, type: Function },
        onError: { required: false, type: Function },
        onClickRow: { required: false, type: Function },
        onClickCell: { required: false, type: Function },
        onClickCaption: { required: false, type: Function },
        

        columns : { required: true, type: Array }, // Array of GridColumn
        
        

        // IN ENV AANPASSEN
        DEFAULT_PAGE_SIZE : { required:false, type:Number, default: __DEFAULT_PAGESIZE__},


        // NIET AANPASSEN
        FIELD_DELIMITER : {required:false, type:String, default:"|"},
        FILTER_WILDCARD : {required:false, type:String, default:"*"},
    },    

    data() {
        return {            
            order : [
                //{ column : "id", direction : "ASC"},
            ],

            filter : [
//                { column : "name", filter : FILTER_WILDCARD+"erm"+FILTER_WILDCARD},
//                { column : "code", filter : FILTER_WILDCARD+"FC"+FILTER_WILDCARD},
                //{ column : "code", filter : FILTER_WILDCARD+"71"},
            ],

            page :  1,
            pagesize :   this.DEFAULT_PAGE_SIZE,
            pagecount :  0,
            pages : [],
            items : [],

            filterTypes : [       
            ],

            filtervisible : false
        }
    },
    created() {
        var query = this.updateurlenabled?useRoute().query:{};

        this.columns.forEach((item, idx)=>{
            item.index=idx;
            if((this.sortenabled)&&(item?.sort)) {
                this.order.push({
                    column : item.fieldname,
                    direction : item.sort
                });
            }
        });

        if(Object.keys(query).length>0) this.setQuery(query);
        this.loadData();
    },

    filterQuery(query) {        
        return query
    },

    mounted() {
        this.filterTypes = [
                {key: this.$t("filter.contains"), value:"%_%"},
                {key: this.$t("filter.equals"), value:"_"},
                {key: this.$t("filter.beginswith"), value:"_%"},
                {key: this.$t("filter.endswith"), value:"%_"},                
            ];
    },

    methods: {
        compileOrder() {
            var s=[];

            this.order.forEach((item)=>{                
                item.column.replaceAll(" ","").split(",").forEach(i=>{
                    s.push(i+(item.direction.toUpperCase()!="ASC"?",DESC":",ASC"));
                });
            });

            return s.join(this.FIELD_DELIMITER); 
        },
        compileFilter(includeRemoteFilters = false) {
            var s=[];

            
            if((includeRemoteFilters)&&(this.remotefilters)) for (const [key, value] of Object.entries(this.remotefilters)) {
                s.push(key+","+value);
            };
            

            this.filter.forEach((item)=>{   
                s.push(item.column+","+item.filter);
            });
 
            return s.join(this.FIELD_DELIMITER); 
        },

        compileUrl(includeRemoteFilters = false) {            
            var q= (this.paginationenabled)?[
                "page="+this.page,
                "pagesize="+this.pagesize,
            ]:[];
            
            if(this.sortenabled) {                
                var s=this.compileOrder();
                if(s)q.push("order="+s);
            }

            if(this.filterenabled) {
                var s=this.compileFilter(includeRemoteFilters);
                if(s)q.push("filter="+s);
            }
            return q.join("&");
        },


        filteredFieldnames() {
            var res={};
            this.filter.forEach(item=>{

                this.columns.forEach(col=>{
                    console.log(col.fieldname, col.filterable);
                    if(col.filterable) {
                        var name = item.column.toLowerCase();

                        var a=col.fieldname.split(',');
                        var caption = this.$t(col.caption)+(a.length>1?"."+this.$t("field."+name+".label"):"");                    

                        if(a.includes(name))   {
                            
                            res[caption]=item;
                        }    
                    }
                });                
            });
            
            return res;
        },

        unFilteredFieldnames() {
            var res={}, ff = this.filteredFieldnames();
            this.columns.forEach(col=>{
                console.log(col.fieldname, col.filterable);
                if((col.filterable)&&(col.fieldname)) {
                    var a=col.fieldname.split(',');

                    a.forEach(name=>{
                        var caption = this.$t(col.caption)+(a.length>1?"."+this.$t("field."+name+".label"):"");                    
                        
                        if(!ff.hasOwnProperty(caption))
                            res[caption]=name;

                    });
                }
            });
            return res;
        },
 

        cardDefinition() {
            var rows={};
            var maxCol = 0, maxRow = 0;
            this.columns.forEach((col,idx)=>{
                if(col.card){
                    if(col.card.col>maxCol) maxCol = col.card.col;
                    if(col.card.row>maxCol) maxRow = col.card.row;                    
                }
            });


            for(var row=0;row<=maxRow;row++) {
                rows[row] = {
                    align : "",
                    columns : []
                };
                for(var col=0;col<=maxCol;col++) {
                    rows[row].columns[col]={
                    align : "",
                    width : 0,
                    colspan : 1,
                    fields : []
                };;
                }
            }
            this.columns.forEach((col,idx)=>{
                
                if(col.card){
                    var align = col.card?.align;
                    if(!align) align = col?.align;                    
                    
                    if(align) rows[col.card.row].columns[col.card.col].align = align;

                    if(col.card.width) if(col.card.width> rows[col.card.row].columns[col.card.col].width)  rows[col.card.row].columns[col.card.col].width = col.card.width;

                    rows[col.card.row].columns[col.card.col].fields.push(col);
                }
            });  


            for(var col=0;col<=maxCol;col++) {
                var max= 0;

                for(var row=0;row<=maxRow;row++) {
                    if(rows[row].columns[col].width>max) max =rows[row].columns[col].width;
                }

                for(var row=0;row<=maxRow;row++) rows[row].columns[col].width=max;
            }

 
            for(var row=0;row<=maxRow;row++) {
                var cbt = 1;
                for(var col=maxCol;col>=0;col--) {
                   
                    if(rows[row].columns[col].fields.length>0) {                        
                        rows[row].columns[col].colspan=cbt;
                        cbt = 1;
                    }
                    else cbt++;
                }
            }

            return rows;
        },

        async loadData() {
            
            if(this.onBeforeLoad) this.onBeforeLoad(this.items);

            //var msg = await onFormGet(this.onLoad, this.compileUrl());

            //if(msg) {
            this.onLoad(this.compileUrl(true))
                .then(msg => {

                    this.items = (msg.hasOwnProperty("data"))?msg.data:msg;

                    this.pagecount = msg?.pagecount || 1;
                
                    if(this.page>this.pagecount) this.page=this.pagecount;

                    var a=[];
                    var i=this.page;
                    for(var j=0;j<2;j++) if(i>1)i--;
                
                    for(var j=0;j<5;j++) if(i+j<=this.pagecount) a[j]=i+j; 
                    
                    this.pages= a;
                    //this.draw();

                //    this.paginate(this.page);
                    
                    this.filtervisible=this.filtervisible||(this.items.length==0);

                    if(this.onAfterLoad) this.onAfterLoad(this.items);                    

                    $(function () {
                        $('#flextable [id^=row_]')
                            .mouseover(function(){
                                //alert($(this).attr("id"));

                                $('#'+$(this).attr("id")+" td").css("background-color", "#E8E8E8");
                            })
                            .mouseout(function(){
                                $('#'+$(this).attr("id")+" td").css("background-color", "");
                            });
                    })

              
                    activateHints();        
    

                })
                .catch(err=>{
                    
                    if(this.onError) this.onError(msg);
                });
        }, 

        setPageAndSize(page, pagesize) {
            this.page = parseInt(page) || this.page;
            this.pagesize = parseInt(pagesize) || this.pagesize;

            if((this.pagecount)&&(this.page>this.pagecount)) this.page=this.pagecount;
            if(this.page<1) this.page=1;
        },

        setFilter(filter){
            //         { column : "name", filter : FILTER_WILDCARD+"erm"+FILTER_WILDCARD},
            this.filter = [];
            if(filter)filter.split(this.FIELD_DELIMITER).forEach(f=>{
                var a=f.split(",");
                if(a.length==2) {
                    var val = a[1].trim();
                    var type = "_";

                    var sw = val.startsWith(this.FILTER_WILDCARD);
                    var ew = val.endsWith(this.FILTER_WILDCARD);
                    
                    if(sw && ew) type = "%_%";
                    else if(sw) type = "%_";
                    else if(ew) type = "_%";

                    this.filter.push({
                        column : a[0].trim(),
                        filter : val,
                        type : type,
                    });
                }
            });
        },
        setOrder(order){
            this.order = [];
            if(order) order.split(this.FIELD_DELIMITER).forEach(f=>{
                var a=f.split(",");
                this.order.push({
                    column : a[0].trim(),
                    direction : ((a.length<2)||(a[1].toUpperCase()=="ASC"))?"ASC":"DESC",
                });                
            });
        },

        setQuery(query){
            this.setFilter(query?.filter);
            this.setOrder(query?.order);
            this.setPageAndSize(query?.page||1,query?.pagesize||this.pagesize);
        },

        getOrderIcon(col) {
            col.__sortdirection= "";
            col.fieldname.split(',').forEach(fieldname=>{
                this.order.forEach(sort=>{
                    if(sort.column==fieldname) {
                        col.__sortdirection=sort.direction;
                    }
                })
            });
            return col.__sortdirection;
        },

        paginate(page) {
            var q = {} ; //this.$route.query;            

            if(this.paginationenabled) {

                this.page = page;
                

                q.page=parseInt(page);

                if(this.DEFAULT_PAGE_SIZE!=this.pagesize) q.pagesize = this.pagesize;

            }

            if(this.sortenabled) {
                var s=this.compileOrder();
                if(s)q.order=s;
            }

            if(this.filterenabled) {
                s=this.compileFilter(false);
                if(s)q.filter=s;
            }

            if(this.updateurlenabled) 
                this.$router.push({ query: q })    
            else {
                this.setQuery(q);
                this.loadData();
            }
        },
        hideToolTips() {
            $('[data-toggle="tooltip"]').tooltip("hide");
        },

        clickColumn(event, col) {

            this.hideToolTips();
            if((this.sortenabled)&&(col.sortable)&&(col?.fieldname)) {
                var cnt = 0;

                col.fieldname.split(",").forEach(fieldname=>{
                    this.order.forEach((item)=>{                
                        if(item.column==fieldname) {
                            cnt++;                          
                            if(item.direction=='ASC') item.direction='DESC';
                            else item.direction='ASC';
                        }
                    });                
                });

                //if(!obj) this.order.unshift({ column : col.fieldname, direction : 'ASC' }); // MORE SORT COLUMNS
                if(!cnt) {
                    this.order=[];
                    col.fieldname.split(",").forEach(item=>{
                        this.order.push({ column : item , direction : 'ASC' }); // JUST ONE COLUMN
                    });
                }
    
                this.paginate(this.page);
            }

            if(col?.onClick!=null)col.onClick(event, col);
            if(this.onClickCaption)this.onClickCaption(event, col);
        },

        clickRow(event, item) {
            this.hideToolTips();
            if(this.onClickRow)this.onClickRow(event, item);
        },
        
        clickCell(event, item, column) {
            this.hideToolTips();
            if(this.onClickCell)this.onClickCell(event, item, column);
        },

   
        columnClickable(col) {            
            return (this.sortenabled&&col.sortable&&(col.fieldname!="")&&(col.fieldname!=null))||(col?.onClick!=null);
        },

        rowClickable(item) {
            return this.onClickRow;
        },
        cellClickable(col, item) {
            return this.onClickCell;
        },

        
        deleteFilter(event, fieldname) {            
            this.hideToolTips();
            this.filter.forEach((item,idx)=>{
                if(item.column==fieldname) {                    
                    this.filter.splice(idx,1);            
                    this.paginate(this.page);
                }
            });
            
            this.filtervisible=this.filter.length;
        },

        showFilterButton(idx) {            
            $("#fbutt"+idx).show();  
        },

        changeFilter(event, idx, filter) {
            this.hideToolTips();
            $("#fbutt"+idx).show();  


            if((event)&&(event.keyCode!=13)) return;
                        
            var type = $("#ftype"+idx).val();
            var fieldname = $("#fname"+idx).val();            
            var text =  $("#ftext"+idx).val();
            
            

            if((text=="")||(fieldname=="")) return false;            

          
            filter.column = fieldname;
            filter.filter = type.replaceAll("%",this.FILTER_WILDCARD).replace("_", text);

            this.paginate(this.page);
          

            this.filtervisible=false;             
        },

        addFilter(event, index) {
            this.hideToolTips();
            
            if((event.target.id=="newfilter_text")&&(event.keyCode!=13)) return;

            

            var fieldname = $("#newfilter_field option:selected").val();
            var type = $("#newfilter_type option:selected").val();
            var text = $("#newfilter_text").val().trim();

            if((text=="")||(fieldname=="")) return false;


            this.filter.push({
                column: fieldname,
                filter: type.replaceAll("%",this.FILTER_WILDCARD).replace("_",text),
                type : type,
            });

            this.paginate(this.page);
            

            $("#newfilter_field option").prop("selected", false);
            $("#newfilter_type option").prop("selected", false);
            $("#newfilter_text").val("");

            this.filtervisible=false; 
        },

        toggleFilter() {
            this.hideToolTips();
            filtervisible=!filtervisible;

        },

        localFilter(item) {            
            if(this.localfilters) for (const [key, value] of Object.entries(this.localfilters)) {
                //console.log(value, typeof value);
                if(typeof value=="function") {
                    if(!value(item)) return false;
                }
                else {

                    var val = String(value);
                    
                    var sw = val.startsWith(this.FILTER_WILDCARD);
                    var ew = val.endsWith(this.FILTER_WILDCARD);

                    if (sw) val = val.substring(this.FILTER_WILDCARD.length);
                    if (ew) val = val.substring(0,value.length-this.FILTER_WILDCARD.length-1);

                    for (const [itemkey, itemvalue] of Object.entries(item)) {
                        var ival =String(itemvalue);
                        if(itemkey==key) {
                            if((!sw)&&(!ew)&&(ival!=val)) return false;
                            else if((sw)&&(ew)&&(ival.indexOf(val)<0)) return false;
                            else if((sw)&&(!ival.endsWith(val))) return false;
                            else if((ew)&&(!ival.startsWith(val))) return false;
                        }
                    };
                }
            };
            return true;
        },


        getFieldValue(fieldname, item) {
            var result = "";
            var a=fieldname.split(".");
            var v = item;

            for(var i=0;i<a.length-1;i++){
                v = v[a[i]];
            }
            return v[a[a.length-1]]
        },
    },

    watch: {
        '$route' : {
            handler(newvalue , oldvalue) { 
                if(this.updateurlenabled){
                    if(oldvalue.name==newvalue.name)
                    this.setQuery(newvalue.query);
                    this.loadData();
                }
            }
        },
        '$i18n.locale' : {
            handler(newvalue , oldvalue) { 
            }
        }
    }
});



</script>

<template> 

<!-- FILTER =============================================================== -->


<div  id="filtercontainer" v-if="this.filterenabled" >
    
    <span  id="filtercaption" class="position-relative" data-toggle="tooltip" data-placement="top" :title="$t('filter.caption')">
        <a class='bi' :class="this.filter.length?' bi-filter-square-fill link-primary':' bi-filter link-secondary' " @click="this.filtervisible=!this.filtervisible"></a>
        <span v-if="!this.filtervisible && this.filter.length" id="filtercounter" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success" style="margin-left:5px;font-size: 0.70em;">
            {{ this.filter.length }}
        </span>
    </span>
    <table id="filter" v-if="this.filtervisible">    
        <tr>
            <th>{{ $t("filter.field") }}</th>
            <th>{{ $t("filter.include") }}</th>
            <th>{{ $t("filter.text") }}</th>
            <th></th>
        </tr>
        <template v-for="(filter,caption) in this.filteredFieldnames()">
            <template v-for="(col,idx) in this.columns" >
                
                <tr  v-if="col.fieldname.split(',').includes(filter.column)">
                    <td>                       
                        <select disabled="true" class="fieldname" :id="'fname'+idx">
                            <option selected :value="filter.column">{{ caption }}</option>
                        </select>
                    </td>                    
                    <td>
                        <select :id="'ftype'+idx" @change="this.showFilterButton(idx)">
                            <option v-for="f in this.filterTypes"  :value="f.value" :selected="filter.type==f.value">{{ f.key }}</option>
                        </select>
                    </td>
                    <td><input @keypress="changeFilter($event, idx, filter)"  :id="'ftext'+idx" type=text :value="filter.filter.replaceAll(this.FILTER_WILDCARD,'')" /></td>                    
                    <td>
                        <a class="bi bi-save link-success" @click.prevent="this.changeFilter(null, idx, filter)" :id="'fbutt'+idx" style="display:none; margin-right:5px;"/>
                        <a class="bi bi-trash link-danger" @click.prevent="this.deleteFilter($event, filter.column)" />
                    </td>
                </tr>
            </template>
        </template>        

        <tr>
            <td>
                <select id="newfilter_field" class="fieldname">
                    <option selected value="">-- field --</option>
                    <option v-for="(fieldname,caption) in this.unFilteredFieldnames()" :value="fieldname">{{caption}}</option>
                </select>
            </td>
            <td>
                <select id="newfilter_type">
                    <option v-for="f in this.filterTypes"  :value="f.value">{{ f.key }}</option>
                </select>
            </td>
            <td><input type=text  id="newfilter_text" value="" @keypress="addFilter($event)"/></td>
            <td><a class="bi bi-plus-circle link-success" @click.prevent="addFilter($event)" /></td>
        </tr>
                
    </table>

</div>
<h5 v-else>{{this.caption}}</h5>

<!-- FIXEDTABLE =============================================================== -->    
    <table id="fixedtable" class="table table-sm table-hover  table-striped ">
        <thead class="thead-dark">
            <tr>
                <th v-for="item in this.columns" 
                    class="align-middle"
                    scope="col"
                    :class="this.columnClickable(item)?'clickable':''" 
                    :style="item?.align?'text-align:'+item.align:''"                     
                    @click.prevent="clickColumn($event,item)" 
                >                     
                    <span v-html="item.onDrawCaption?item.onDrawCaption(item):this.$t(item.caption)"
                        data-toggle="tooltip" 
                        data-placement="top" 
                        :title="(item.onDrawTitle?item.onDrawTitle(item):this.columnClickable(item)?$t('grid.sorton')+' '+$t(item.caption):'')"
                        />
                    <i v-if="this.getOrderIcon(item)"
                        class='bi link-primary sorticon' 
                        :class="item.__sortdirection!='ASC'?'bi-sort-alpha-up-alt':'bi-sort-alpha-down'" /> 
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="items.length==0"><th style='text-align:center' :colspan="columns.length">{{ $t("filter.noresults") }}</th></tr>

            <template v-for="(item, ii) in items">

                <tr v-if="localFilter(item)"
                    :id="'row_'+item.id"                     
                    :class="{'clickable':rowClickable(item)}"
                    @click.prevent="clickRow($event,item)"
                    >
                    <td v-for="(col, ci) in columns" 
                        class="align-middle"
                        :style="col?.align=='right'?'text-align:right':''" 
                        @click.prevent="clickCell($event,item, col)"                    
                        :class="item.enabled && col.fieldname?'enabled':''"
                        :id="'cell_'+ci+'_'+item.id"
                    >                
    
                        <template v-if="col.fieldname"  v-for="(field,idx) in col.fieldname.split(',')">


                            <span v-if="col.onDrawCell" v-html="col.onDrawCell(item, field.trim())"></span>
                            <template v-else-if="getFieldValue(field.trim(), item)">
                                
                                <span>{{ getFieldValue(field.trim(), item) }}</span>

                                <i v-if="(idx>=0)&&(idx<col.fieldname.split(',').length-1)">,&nbsp;</i>
                            </template>
                        </template>

                        <div v-else-if="col.onDrawCell" v-html="col.onDrawCell(item)"></div>
                    </td>
                </tr>
            </template>            
        </tbody>
    </table>

<!-- FLEXTABLE =============================================================== -->
    <div id="flextable">
        <table class="table">
            <tr>
                <td colspan="100" class="align-middle text-end">
                    <div v-if="Object.keys(this.cardDefinition()).length==0" v-html="$t('filter.nocard')" />
                    <div v-else-if="items.length==0">{{ $t("filter.noresults") }}</div>
                    <template v-for="(item,idx) in this.columns">                 
                        <span v-if="item.onDrawCaption" v-html="item.onDrawCaption(item)"  @click.prevent="clickColumn($event,item)" ></span>
                    </template>
                </td>
            </tr>

            <template v-for="(item, iidx) in items"> 
                <template v-for="row in this.cardDefinition()"> 
                    <tr class="clickable"
                        :id="'row_'+item.id" 
                        :class="iidx&1?'odd':'even'" 
                        :style="'border-left : 4px solid silver'"          
                        @click.prevent="clickRow($event,item)">

                        <template v-for="(cols,cidx) in row.columns" >
                            <td v-if="cols.fields.length>0" :id="'cell_'+cidx+'_'+iidx" 
                                class="align-middle" 
                                :colspan="cols.colspan"
                                :class="cols?.align=='right'?'text-end':(cols?.align=='left'?'text-begin':'')"
                                :style="cols.width?'width:'+cols.width:''"
                            > 

                                <span v-for="col in cols.fields"                          
                                    :class="item.enabled && col.fieldname?'enabled':''"
                                    :id="'cell_'+col.index+'_'+item.id"
                                    @click.prevent="clickCell($event,item, col)">         
                                    
                                    <template v-if="col.fieldname"  v-for="(field,idx) in col.fieldname.split(',')">
            

                                        <span v-if="col.onDrawCell" v-html="col.onDrawCell(item, field.trim())"></span>
                            <template v-else-if="getFieldValue(field.trim(), item)">
                                
                                <span>{{ getFieldValue(field.trim(), item) }}</span>

                                <i v-if="(idx>=0)&&(idx<col.fieldname.split(',').length-1)">,&nbsp;</i>
                            </template>

                              
                                    </template>

                                    <span v-else-if="col.onDrawCell" v-html="col.onDrawCell(item)"></span>
                                    
                                </span>
                            </td>
                        </template>
                    </tr>
                </template>
                <tr><td class="spacer"> </td></tr>
            </template>
        </table>
    </div>
 

<!-- PAGINATION =============================================================== -->    
    <nav aria-label="" v-if="(this.paginationenabled)&&(this.pagecount>1)">
        <ul class="pagination justify-content-center pagination-lg">
            <li class="page-item" :class="{ disabled: this.page <= 1 }">
                <a class="page-link bi bi-chevron-bar-left" @click="this.paginate(1)" />
            </li>
            <li class="page-item" :class="{ disabled: this.page <= 1 }">
                <a class="page-link bi bi-chevron-left" @click="this.paginate(this.page - 1)" />                    
            </li>

            <template v-for="n in this.pages">
                <li class="page-item" :class="[n == this.page ? ['disabled', ''] : '']">
                    <a class="page-link " @click="this.paginate(n)">{{ n }}</a>
                </li>
            </template>

            <li class="page-item" :class="{ disabled: this.page >= this.pagecount }">
                <a class="page-link bi bi-chevron-right" @click="this.paginate(this.page + 1)" /> 
            </li>
            <li class="page-item" :class="{ disabled: this.page >= this.pagecount }">
                <a class="page-link bi bi-chevron-bar-right" @click="this.paginate(this.pagecount)" />
            </li>

        </ul>
    </nav>     
</template>


<style >

@media (max-width: 990px)  {
    a.activitybutton,
    #fixedtable {
        display : none;
    } 
    
}

@media (min-width: 991px)  {
    a.activitybutton {
        display : inline-block; 
    }
    #flextable {
        display : none; 
    }
}
 

table.table thead th i.sorticon {
    font-size: 1.0rem;
}

.clickable
{
    cursor : pointer;        
}

#flextable table tr.even {
    background-color : #F8F8F8;
}

#flextable table span {
    padding:0;
    margin : 0;
}

#flextable table td {
    padding-left:10px;
}

#flextable table td.spacer {
    padding-bottom:10px;
} 

#filtercaption {
    border : 0;
    padding : 0;
    margin :0;
}

table#filter {
    margin :0;
    border : 2px solid white;
    margin-bottom : 20px;
}
 
table#filter *{
    font-size: 1rem;    
}

table#filter th{
    font-size: 0.8rem;
    color: navy;
    border-bottom : 1px solid silver;
    padding:0;margin:0;
}

table#filter td{
    padding:5px;
    padding-top:4px;
    padding-bottom:2px;
}

table#filter select{    
    height : 22px;
    
    font-size: 0.9rem;
}
#filter select.fieldname {
    width : 150px;
}
 
table#filter input{
    
    border : 1px solid silver;
    height : 20px;
    width : 150px;
}


</style>