import { defineStore } from 'pinia';
import { fetchWrapper, storeDbActions, recurseAdd } from '@/helpers';


export const useCmsDevicesStore = defineStore("cms.devices", {
    state: () => ({
        baseUrl : `${import.meta.env.VITE_API_URL}/cms/device`,
    }),
    actions: {
        ...storeDbActions(),        

        async loglist( deviceid, ...args) {
            let q = [];

            recurseAdd(q, ...args);

            return fetchWrapper.get(`${this.baseUrl}/${deviceid}/log`+(q?"?"+q.join("&"):""));
        }, 

    }
});
