<script setup>
import { useI18n } from "vue-i18n";
import { ref } from 'vue'

import { SUPPORTED_LOCALES, loadLanguageAsync, i18n } from '@/i18n.js';

//const i18n = useI18n({ useScope: 'global' })    

 
const { t } = useI18n();
const locale = ref(i18n.global.locale);
const languages = SUPPORTED_LOCALES;

const updateLanguage = function (event,code)  {
  locale.value = code;
  //i18n.locale.value = code;
  loadLanguageAsync(code);
  localStorage.setItem("locale", code);
};



</script>

<template> 
  <div class="nav-item dropdown" >
     <a class="nav-link locale rootitem" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" :title="t(`menu.locale`)">
      <img :src="`/24x18/`+locale+`.png`">
    </a>
    
    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      <div class="nav-item" v-for="(lang, i) in languages" :key="`Lang${i}`" :value="lang.value">
        <a class="dropdown-item" @click="updateLanguage($event,lang.value)" :key="`Lang${i}`">
          <img :src="`/24x18/`+lang.value+`.png`"   :alt="lang.key">
          {{ lang.key }}
        </a>            
      </div>
    </div>
  </div>
</template>

<style scoped>

a.locale img{
  margin-top : 8px;  
  margin-bottom : 8px;
}

a.dropdown-item img {
  margin-right : 5px;
}

a.dropdown-item {
  color : #444444;
}

a.dropdown-item:hover {
    color : #004b93;
}
</style>