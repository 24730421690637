import { defineStore } from 'pinia';
import { fetchWrapper, storeDbActions } from '@/helpers';

export const usenNotificationsStore = defineStore({
    id: 'notificationStore',
    state: () => ({    
        baseUrl : `${import.meta.env.VITE_API_URL}/notification`,
    }),
    actions: {    
        ...storeDbActions(),
 
        async getActiveNotifications( language="en") {
            return await fetchWrapper.get(`${this.baseUrl}/active/${language}`);
        },    

    }
});
