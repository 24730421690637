import { defineStore } from 'pinia';
import { fetchWrapper, storeDbActions } from '@/helpers';

export const useTextsStore = defineStore({
    id: 'texts',
    state: () => ({    
        baseUrl : `${import.meta.env.VITE_API_URL}/text`,
    }),
    actions: {    
        ...storeDbActions(),

        async getLanguageText(id, language="en") {
            return await fetchWrapper.get(`${this.baseUrl}/${id}/${language}`);
        },           
    
    }
});
