import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n'

export const useBreadcrumbStore = defineStore({
    id: 'breadcrumb',
    state: () => ({
        path : [],
    }),
    
    actions: {        
        capitalize(s) {
            return (s.length)?s[0].toUpperCase() + s.slice(1):s;
        },

        load(s) {
            this.path = [];
            
            var a=s.split("/").slice(1);

            if((a.length>0) && (a[a.length-1]=="")) a=a.slice(0,-1);

            for(var i=0;i<a.length;i++)
                this.append(a[i]);

        },

        delete(idx) {
            this.path.splice(idx,1);
            console.log(this.path);
            
            //console.log(this.path.splice(0,1));
        },

        append(name, caption="") {
            this.path.push({
                caption : this.capitalize(caption?caption:name),
                name : name
            });
        },

        replace(id, caption) {
            for(var i=0;i<this.path.length;i++){
                
                if(this.path[i].name.toUpperCase()==String(id).toUpperCase()) {
                    
                    this.path[i].caption = caption;                    
                }
            }
            
        }
    },

});