<script setup>
import { useRoute } from 'vue-router';
import { useBreadcrumbStore } from '@/stores';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n'

const { t } = useI18n();
const store = useBreadcrumbStore();
const route = useRoute();


store.$subscribe(({mutation}, state) =>{
    if(state.status || state.error || state.loading) {
    }    
})

watch(route, (newvalue) => {             
    store.load(newvalue.path);
});

function makePath(idx) {
    var a = ['menu'];

    if(!isNaN(parseInt(store.path[idx].name))) {
        return store.path[idx].caption;
    }

    var hasNumber = false;
    var s = store.path.slice(0,idx+1);

    for(var i=0;i<s.length;i++) {
        var item = s[i];

        if(isNaN(parseInt(item.name))) {
            if(hasNumber) return (item.caption);
            else a.push(item.name.toLowerCase());
        }
        else hasNumber=true;
    }
    
    if(a.length-1<store.path.length)a.push("caption");
 
    return t(a.join("."));
}

</script>

<template>  
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">{{ $t("menu.home") }}</li>
            <li v-for="(item,idx) in store.path" class="breadcrumb-item" :id="'bread_'+idx">
                <template v-if="isNaN(item.caption)">
                 <!--   {{ item.caption }}  -->
                    {{ makePath(idx) }}
                </template>
            </li>
        </ol>
    </nav>
</template>

<style scoped>


@media (max-width: 990px)  {
.breadcrumb-item {    
    font-size: 0.7rem;
    } 
    .breadcrumb-item {
        padding-left : 2px;    
    }
    .breadcrumb-item+.breadcrumb-item::before {     
        padding-right: 2px; 
    }    
}

@media (min-width: 991px)  {
.breadcrumb-item {    
    font-size: 0.95rem;
    }
}

</style>