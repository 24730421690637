import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import axios from 'axios';

export const SUPPORTED_LOCALES = [
  { value : "nl", key : "Nederlands"}, 
  { value : "en", key : "English" }, 
  { value : "de", key : "Deutsch" },
  { value : "fr", key : "Français" },
  { value : "pl", key : "Polski" },
  { value : "ro", key : "Română" }
  ];

export const i18n = createI18n({
    locale: (localStorage.getItem('locale') || 'en'),
    fallbackLocale: "en",
    legacy: false, // you must set `false`, to use Composition API
    messages: {  en },    
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    fallbackWarn: false,
    missingWarn: false, 
  })


  const loadedLanguages = ['en'] // our default language that is preloaded

  function setI18nLanguage (lang) {
    if (i18n.mode === 'legacy') {
      i18n.global.locale = lang
    } 
    else {
      i18n.global.locale.value = lang
    }

    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
  }
  
  export async function loadLanguageAsync(lang) {
    
    // If the same language
    if (i18n.global.locale === lang) {
      return Promise.resolve(setI18nLanguage(lang))
    }
  
    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
      return Promise.resolve(setI18nLanguage(lang))
    }  
    // If the language hasn't been loaded yet
    return import(/* webpackChunkName: "lang-[request]" */ `./locales/${lang}.json`).then(
      messages => {
        i18n.global.setLocaleMessage(lang, messages.default)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      }
    ).catch(err=>{
        alert(err)
    });
  }  