import { defineStore } from 'pinia';
import { fetchWrapper, storeDbActions } from '@/helpers';

export const useCmsTextsStore = defineStore({
    id: 'cms_texts',
    state: () => ({
        baseUrl : `${import.meta.env.VITE_API_URL}/cms/text`,
    }),
    actions: {    
        ...storeDbActions(),        
  
        async getLanguageText(id, language="en") {
            return await fetchWrapper.get(`${baseUrl}/${id}/${language}`);
        },    

    }
});
