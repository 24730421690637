<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n();
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { useStatusStore } from '@/stores';

const status = useStatusStore();
const route = useRoute();
let  timeout = null;


status.$subscribe(({mutation}, state) =>{
    if(state.status) {
        setTimeout(function() { 
            $("#statuscontainer").show();
        },100);

        if(timeout)clearTimeout(timeout);

        timeout = setTimeout(function() { 
            $("#statuscontainer").fadeOut(1000).promise().done((obj)=>{
                status.status = "";
            });
        }, 10000);
    }

    
    if((status.error)&&($('#staticBackdrop').modal)) $('#staticBackdrop').modal('show');
    
})

watch(route, (newvalue, oldvalue) => {     

});

$(document).ready(function() {
    if(status.error) $('#staticBackdrop').modal('show');
});

</script>

<template>
    <div id=statuscontainer style="position : fixed;left: 50%; transform: translateX(-50%);  bottom: 0;" class="col-sm-6">
        <div class="alert alert-success mt-0 mb-3"  v-if="status.status">
            <i class="bi bi-info-circle-fill" style="font-size: 2.5rem; color: cornflowerblue;"/>
            {{ t(status.status) }}        
        </div>
    </div>
 
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">{{t("general.error")}}</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table>
                    <tr>
                        <td><i class="bi bi-cloud-rain-fill" style="padding:20px;font-size: 2.5rem; color:crimson;"/></td>
                        <td>{{ status.error?t(status.error):"" }}</td>
                    </tr>
                </table>
                
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
    </div>



    <div v-if="status.loading" id="loading" style="position : fixed;left: 50%; transform: translateX(-50%) translateY(-50%);  bottom: 50%; z-index : 911169">
        <div class="d-flex justify-content-center">
            <div class="spinner-border text-primary"  style="width: 3rem; height: 3rem;"  role="status" />    
        </div>
    </div>

</template>

<style scoped>


div.alert {
    padding : 0;
    display: flex;		
  	flex-direction: row;
    align-items: center;
}

div.alert .bi{
    padding : 0px 10px 0px 10px;
}

div.alert .spinner-border
{
    margin : 15px;    
}
</style>