<script setup>
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTextsStore } from '@/stores';
const { t } = useI18n();
const i18n = useI18n({ useScope: 'global' })    

 
var text = ref({
    caption : t("general.loading") ,
    body : t("general.loading")
});

function loadText(){
    useTextsStore().getLanguageText(18, i18n.locale.value)
        .then((msg)=>{        
            text.value=msg;
        })
}

onMounted(() => {
    loadText()
});

watch(i18n.locale, (newvalue)=>{
    loadText()
});

</script>
<template>
  <h5>{{ text.caption }}</h5>
  <div v-html="text.body.replace('\n','<br>')" />
</template>