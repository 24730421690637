<script>
import { defineComponent} from 'vue';
import {Html5QrcodeScanner} from "html5-qrcode";
import {Html5Qrcode} from "html5-qrcode";
import { ref } from 'vue';
import { useI18n } from 'vue-i18n'


export default defineComponent( {
    name : "Confirm",
    props: {
        //rejectLabel : { required: false, type: String, default : "Cancel" },
        //cancelLabel : { required: false, type: String, default : "OK" },
    },    

    data() {
        return {    
            cameras : ([]) ,        
            cameraId : 0,
            html5QrcodeScanner : null,
            html5QrCode : null,
            decodedText : null,
            decodedResult : null,
        }
    },
    methods: {

        close() {
            if(this.html5QrCode){
                this.html5QrCode.stop().then((ignore) => {
                    // QR Code scanning is stopped.
                    }).catch((err) => {
                    // Stop failed, handle it.
                    });
                delete this.html5QrCode;
                this.html5QrCode=null;    
            }


            if(this.html5QrcodeScanner) {
                
                this.html5QrcodeScanner.clear();
                delete this.html5QrcodeScanner ;
            }
            this.html5QrcodeScanner = null;
            
            $("#qrcontainer").hide();
            $("#selector").hide();


            
        },

        async getCameras(){
            this.cameras=[];
            await Html5Qrcode.getCameras()
                .then(devices => {
                    if (devices && devices.length) {

                        for(var i=0;i<devices.length;i++){
                            this.cameras.push(devices[i]);
                        }
                    }
                })
               .catch(err => {
                // handle err
                });
    

        },

        async scan(cameraId=null) {

            if(false) {
            if(this.html5QrcodeScanner)return;

            this.decodedResult=null;
            this.decodedText=null;            
            
            this.html5QrcodeScanner = new Html5QrcodeScanner("reader", { fps: 10, qrbox: 250 });

            $("#qrcontainer").show();
            
            this.html5QrcodeScanner.render(
                (decodedText, decodedResult)=>{
                    this.decodedResult=decodedResult;
                    this.decodedText=decodedText.substring(-17);                              
                    this.close();

                    if(this.onSuccess)this.onSuccess(this.decodedText, this.decodedResult);
                },
                (err)=>{
                    //this.close();
                }        
            );
            }

            else {
                $("#qrcontainer").show();
       
                if(cameraId)this.cameraId=cameraId;
       
                if(this.cameraId){
                    $("#selector").hide();


                    this.html5QrCode = new Html5Qrcode("reader");

                    this.html5QrCode.start(
                        this.cameraId, 
                        {
                            fps: 10,    // Optional, frame per seconds for qr code scanning                            
                            qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
                        },
                        (decodedText, decodedResult)=> {
                            this.decodedResult=decodedResult;
                            this.decodedText=decodedText.substring(-17);       
                            
                            this.close();
                            if(this.onSuccess)this.onSuccess(this.decodedText, this.decodedResult);
                        },
                        (errorMessage) => {
                            // parse error, ignore it.
                        })
                        .catch((err) => {
                            // Start failed, handle it.
                        });
                }
                else {
                    await this.getCameras();                   

                    if(this.cameras.length==0){
                        this.close();
                    }
                    else if(this.cameras.length==1)this.scan(this.cameras[0].id);                    
                    else $("#selector").show();
                }
            }       
        },       
    },

watch: {
     
},

});

</script>

<template>
    <div id="qrcontainer" style="display:none;">
        <div id=selector  style="display:none;">
            <div>{{ $t("general.selectcamera") }}</div>
            <a class="btn btn-success" v-for="item in this.cameras" @click="this.scan(item.id)">{{ item.label }}</a>
        </div>

        <div id="reader"></div>
        

        <div id="buttons">
            <button class="btn btn-warning" @click="this.close($event)">{{ $t("general.close") }}</button>
        </div>
    </div>
</template>

<style scoped>
 
#selector {
    width : 310px;
    padding : 20px;
    background-color : white;
    text-align:center;

}
#selector .btn{
    width: 100%;
    margin-top : 10px;
}

#selector, 
#reader {
    position : fixed;
    left: 50%;     
    top: 50%; 
    transform: translateX(-50%) translateY(-50%);  

}
#buttons {
    width : 200px;
    
    
    text-align:center;
    position : fixed;
    left: 50%;     
    bottom: 5%; 
    transform: translateX(-50%) translateY(-50%);  
}

#qrcontainer {
  position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(51,51,51,0.7);
    z-index: 10;
}    
</style>