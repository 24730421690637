<script setup>
import { useAuthStore } from '@/stores';
import { router } from '@/helpers';

if(!useAuthStore().loggedIn()) router.push('/Login');
else router.push('/Devices');

</script>

<template>
	{{ useAuthStore().user }}
</template>