import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import { i18n , loadLanguageAsync} from './i18n.js';
import { router } from './helpers';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';


loadLanguageAsync((localStorage.getItem('locale') || 'en'));

  
const app = createApp(App);
 
app.use(createPinia());
app.use(router);
app.use(i18n);
app.use(PrimeVue);
app.use(ConfirmationService);

import 'primevue/resources/themes/aura-light-green/theme.css'

app.mount('#app');

