import { defineStore } from 'pinia';
import { storeDbActions } from '@/helpers';

export const useCmsMenusStore = defineStore("cms.menus", {
    state: () => ({
        baseUrl : `${import.meta.env.VITE_API_URL}/cms/menu`,
    }),
    actions: {
        ...storeDbActions(),    
    }
});
