import { defineStore } from 'pinia';
import { fetchWrapper, storeDbActions } from '@/helpers';

export const useCmsNotificationsStore = defineStore({
    id: 'cms_notifications',
    state: () => ({
        baseUrl : `${import.meta.env.VITE_API_URL}/cms/notification`,
    }),
    actions: {    
        ...storeDbActions(),      

    }
});
