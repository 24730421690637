import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers';
import VueJwtDecode from 'vue-jwt-decode';
import { useMenuStore } from '@/stores';
import { useLocalStorage, useSessionStorage } from "@vueuse/core"

const baseUrl = `${import.meta.env.VITE_API_URL}`;


export const useAuthStore = defineStore("auth", {
    state: () => ({        
        user: useLocalStorage("membersession", {}),        
        remember : useLocalStorage("memberberry", {
                email : "default",
                password : "default",
                membered : false
            }
        ),

        loginCounter : useSessionStorage("loginCounter", {
            success : {
                count : 0,
                time : null,
            },
            error : {
                count : 0,
                time : null,
            },
        }),

        returnUrl: null
    }),

    actions: {
        async login(email, password, remember = false) {            
            return fetchWrapper.post(`${baseUrl}/auth`, { email, password })
                .then(async (message)=>{
                    this.user=message;
                    this.remember = {
                        email:remember?email:null,
                        password:remember?password:null,
                        membered : remember
                    };
        
                    const menuStore = useMenuStore();
                    await menuStore.getAll();

              //      console.log("USER",this.user);

                    return message;
                })
                .catch((error)=>{
                    this.user={};
                    
                    throw error;
                });
        },

        async checklogin() {

            if(!this.loggedIn()) return null;

            var decoded = VueJwtDecode.decode(useAuthStore().user.token);
            var minDiff = Math.floor((new Date(decoded.exp * 1000)- new Date())/60000);
 
            if(minDiff<10){
                await fetchWrapper.get(`${baseUrl}/auth/refresh`)
                .then(async (message)=>{
                    this.user=message;
                    return message;
                })
                .catch((error)=>{
                    this.user={};
                    throw "Unauthorized";
                });

            }
            else return this.user;

        },

        loggedIn() {
            return !!this.user?.token;
        },

        updateUserdata(data) {
            for (const [key, value] of Object.entries(data)) {
                if(key in this.user) this.user[key] = value;
            };
            
            this.remember.email=this.user.email;            
        },

        async logout() {
            this.user = null;

            //console.log("LOGOUT");

            const menuStore = useMenuStore();
            await menuStore.getAll();
        }
    }
});
