import { defineStore } from 'pinia';
import { storeDbActions } from '@/helpers';

export const useRoleStore = defineStore({
    id: 'role',
    state: () => ({    
        baseUrl : `${import.meta.env.VITE_API_URL}/role`,
    }),

    actions: {
        ...storeDbActions(),
    }
});
