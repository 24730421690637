<script>
import { defineComponent} from 'vue';
import { useConfirm } from "primevue/useconfirm";


export default defineComponent( {
    name : "Confirm",
    props: {
        rejectLabel : { required: false, type: String, default : "" },
        acceptLabel : { required: false, type: String, default : "" },
    },    

    data() {
        return {            
            confirmObj : useConfirm(),            
        }
    },
    methods: {        
        confirm(message, onAccept, onReject=null, header=null ){
            this.confirmObj.require({
                message: message,
                header: header?header:this.$t("general.alertheader"),
                icon: 'bi bi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                rejectLabel: this.rejectLabel ?this._rejectLabel:this.$t('button.cancel'),
                acceptLabel: this.acceptLabel ?this._acceptLabel:this.$t('button.ok'),
                accept: () => {   
                    if(onAccept)onAccept();
                },
                reject: () => {
                    if(onReject)onReject();
                },        
            });
        }       
    },
});
</script>

<template>      
 </template>