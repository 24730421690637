import { fetchWrapper } from '@/helpers';


export function recurseAdd(result, ...args) {
    for(var i=0; i<args.length; i++){
        if(Array.isArray(args[i])){
            for(var j=0; j<args[i].length; j++)
                recurseAdd(result, args[i][j]);
        }

        else if(typeof args[i] == "object") {
            for (const [key, value] of Object.entries(args[i])) {
                result.push(key+"="+value);         
            } 
        }        
        else result.push(args[i]);
    }
}

export function storeDbActions() {

    return {
        async list( ...args) {
            let q = [];

            recurseAdd(q, ...args);

            return fetchWrapper.get(this.baseUrl+(q?"?"+q.join("&"):""));
        }, 

        async optionList( ...args) {
        
            return await this.list({page:1,pagesize:1000,order:"name",filter:"enabled,true"})
                .then(data => {
                    var result = [];
                    data.data.forEach(element => {
                        result.push({key: element.name, value: element.id});               
                    });        
                    return result;                        
                });
          
        },

        async get(id) {
            return fetchWrapper.get(this.baseUrl+`/${id}`);
        },    

        async add(data) {
            return await fetchWrapper.post(this.baseUrl, data);
        },
        
        async update(id, data) {
            return await fetchWrapper.put(this.baseUrl+`/${id}`, data);
        },
        
        async delete(id) {
            return await fetchWrapper.delete(this.baseUrl+`/${id}`);
        },
    }

}