<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useMenuStore } from '@/stores';
import { MenuItemComponent } from '@/components';
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router';

const { t } = useI18n();
const menuStore = useMenuStore();
const { items } = storeToRefs(menuStore);
 
</script>

<template>
    <div  id="fixedmenucontainer">
        <RouterLink class="navbar-brand" href="#" to="/Devices"><img id=brand src="/logo-v2.svg" /></RouterLink> 
        <div id="fixedmenu" >
            <MenuItemComponent :items="items" :parentid="0" path="menu."/>
        </div>
    </div>
</template>

<style scoped>


@media (max-width: 990px)  {
    img#brand {    
        margin-top: 12px;
        margin-left : 5px;
        height : 23px;
    } 
    
}

@media (min-width: 991px)  {
    img#brand {    
        height : 40px;
        margin-top: 5px;
        margin-left : 15px;
    }
}
 
#fixedmenu {
    float : right;
}

#fixedmenucontainer {
    margin-bottom : 10px;
}


</style>