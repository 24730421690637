import { defineStore } from 'pinia';
import { fetchWrapper, storeDbActions, recurseAdd } from '@/helpers';

export const useCmsUsersStore = defineStore("cms.users", {
    state: () => ({
        baseUrl : `${import.meta.env.VITE_API_URL}/cms/user`,
    }),
    actions: {
        ...storeDbActions(),

        async loglist( userid, ...args) {
            let q = [];
            recurseAdd(q, ...args);
            return fetchWrapper.get(`${this.baseUrl}/${userid}/log`+(q?"?"+q.join("&"):""));
        }, 

    }

    
});
