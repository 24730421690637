import { defineStore } from 'pinia';
import { fetchWrapper, storeDbActions } from '@/helpers';

const baseUrl = `${import.meta.env.VITE_API_URL}/user`;

export const useUsersStore = defineStore({
    id: 'users',
    state: () => ({
        users: {}
    }),
    actions: {        
        ...storeDbActions(),

        async loadData(...args) {
            return this.performLoadData(`${baseUrl}`,...args);
        },   

        async createUser(data) { //  
            return await fetchWrapper.post(`${baseUrl}`, data);
        },

        async getUser(userid) {
            return await fetchWrapper.get(`${baseUrl}`);                      
        },

        async updateUser(data) {
            return await fetchWrapper.put(`${baseUrl}`, data);
        },

        async removeUser(data) {
            return await fetchWrapper.delete(`${baseUrl}`, data); // password
        },

        async validateUser(code) {
            return await fetchWrapper.post(`${baseUrl}/validate`, { code });
        },        

        async getResetPassword(data) {
            return  await fetchWrapper.put(`${baseUrl}/resetpassword`, data);
        },
        
        async resetPassword(data) {
            return await fetchWrapper.post(`${baseUrl}/resetpassword/`, data);
        },

        async changePassword(data) {
            return await fetchWrapper.put(`${baseUrl}/changepassword`, data); //{oldpassword, newpassword}
        },

        async changeEmail(data) {
            return await fetchWrapper.put(`${baseUrl}/changeemail`, data); //{email, password}
        },

    }
});
